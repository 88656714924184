import React from 'react'
import { Route } from 'react-router-dom'
import BlockIcon from '@material-ui/icons/Block'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/blackList'

const PATH = '/black_lists'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: BlockIcon,
      },
    },
    {
      title: 'Próby logowania',
      resourceAccess: schema.access,
    }
  )

export default routes
