import React from 'react'
import _blockEvent from './_blockEvent'
import _blockSpectacle from './_blockSpectacle'
import photoSchema from './photo'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'
import { Alert } from '@material-ui/lab'
import fileSchema from './media'

const schema = {
  endpoint: '/api/events',
  clone_endpoint: '/api/events/clone',
  select_endpoint: '/api/events_select',
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title1: {
            description: 'Tytuł linia 1',
          },
          title2: {
            description: 'Tytuł linia 2',
          },
          subtitle: {
            description: 'Podtytuł',
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          shortDescription: {
            description: 'Krótki opis',
            label: 'Krótki opis',
            type: 'textarea',
          },

          duration: {},
          breaks: {},
          price: {},
          recipients: {},
          importantInfo: {
            type: 'textarea',
          },
          incomingRoles: {
            description: 'W obsadzie najbliższych spektakli',
            type: 'tinymce',
          },

          eventContactShtType: {
            type: 'eventContactShtType',
            description: 'Informacje kontaktowe',
            mainLabel: 'Informacje kontaktowe',
            label: 'Nagłówek',
            label2: 'Tytuł',
            label3: 'Telefon 1',
            label4: 'Telefon 2',
            label5: 'Email',
            label6: 'Adres',
            name: 'contactHeader',
            name2: 'contactTitle',
            name3: 'contactPhone1',
            name4: 'contactPhone2',
            name5: 'contactEmail',
            name6: 'contactAdress',
          },
          eventEduShtType: {
            type: 'eventEduShtType',
            description: 'Linkowanie do platformy edukacyjnej',
            mainLabel: 'Linkowanie do platformy edukacyjnej',
            label: 'Nagłówek',
            label2: 'Tytuł link 1',
            label3: 'Link 1',
            label4: 'Tytuł link 2',
            label5: 'Link 2',
            label6: 'Tytuł link 3',
            label7: 'Link 3',
            name: 'contactHeader',
            name2: 'contactLinkBtn1',
            name3: 'contactLink1',
            name4: 'contactLinkBtn2',
            name5: 'contactLink2',
            name6: 'contactLinkBtn3',
            name7: 'contactLink3',
          },

          avardText: {
            description: 'Nagrody - opis',
            type: 'tinymce',
          },

          authorImages: {
            description: 'Autor zdjęć',
          },

          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },

      location: {
        type: 'resource',
        endpoint: `/api/event_locations?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_LOCATION_SELECT',
      },

      cycle: {
        type: 'resource',
        endpoint: `/api/event_category_cycles?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Wybierz cykl - kategorię',
      },

      // spektakle
      media: {
        type: 'image',
        description: 'Zdjęcie dla spektaklu',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
          property: 'media',
        },
      },

      // wydarzenia
      mediaDetail: {
        type: 'image',
        description: 'Zdjęcie dla wydarzenia',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs', //?name=
          property: 'mediaDetail',
        },
      },
      /*
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideMediaInDetail: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
          property: 'mediaDetail',
        },
      },*/
      datePremiere: {
        type: 'date',
        description: 'Data premiery',
        hint: 'wykorzystana w archiwum, sortowanie po dacie',
        // validate: ['required'],
      },

      authorText: {
        description: 'Autor',
        hint: 'jesli wpisany zastępuje autora z listy poniżej',
      },
      author: {
        type: 'resource',
        endpoint: `/api/crew_filmmakers?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'lub Autor z listy',
      },
      directorText: {
        description: 'Reżyser',
        hint: 'jesli wpisany zastępuje reżysera z listy poniżej',
      },
      director: {
        type: 'resource',
        endpoint: `/api/crew_filmmakers?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'lub Reżyser z listy',
      },
      makers: {
        // type: 'embeddedCollection',
        type: 'collection',
        statable: true,
        label: 'Twórcy',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: '#EventMaker',
        endpoint: '/api/event_makers',
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                description: 'Funkcja/stanowisko',
                hint: 'wymagane, aby wyswietlić twórcę w detalu',
              },
              name: {
                description: 'Imię',
              },
              surname: {
                description: 'Nazwisko',
              },
            },
          },
          crewFilmmaker: {
            type: 'resource',
            endpoint: `/api/crew_filmmakers?pagination=false`,
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
            label: 'Twórca',
            hint: 'gdy brak wpisanego wyżej, mozna wybrać z listy twórcę',
          },
        },
      },
      roles: {
        // type: 'embeddedCollection',
        type: 'collection',
        statable: true,
        label: 'Obsada',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: '#EventRole',
        endpoint: '/api/event_roles',
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                description: 'Rola aktora',
                hint: 'wymagane, aby wyswietlić aktora w detalu',
              },
              name: {
                description: 'Imię',
              },
              surname: {
                description: 'Nazwisko',
              },
            },
          },
          crewItem: {
            type: 'resource',
            endpoint: `/api/crew_items?pagination=false`,
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
            label: 'Aktor',
            hint: 'gdy brak wpisanego wyżej, mozna wybrać z listy aktora',
          },
        },
      },
      statics: {
        // type: 'embeddedCollection',
        type: 'collection',
        statable: true,
        label: 'Statyści',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: '#EventStatic',
        endpoint: '/api/event_statics',
        properties: {
          translations: {
            type: 'translation',
            properties: {
              name: {
                description: 'Imię',
              },
              surname: {
                description: 'Nazwisko',
              },
            },
          },
          crewItem: {
            type: 'resource',
            endpoint: `/api/crew_items?pagination=false`,
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
            label: 'lub Aktor',
            hint: 'gdy brak wpisanego wyżej, mozna wybrać z listy aktora',
          },
        },
      },

      archive: {
        description: 'Archiwum',
        hint: 'prezniesione do archiwum',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },

      galleryType: {
        type: 'choice',
        description: 'Typ galleri',
        choices: {
          normal: '9 poziomych zdjęć',
          big_last: '8 zdjęć, piąte pionowe',
        },
        validate: ['required'],
        fullWidth: false,
      },

      bilety24TitleId: {
        description: 'ID Bilety24',
        hint: 'z listy tytułów',
        fullWidth: false,
      },
    },
  },
  subresources: {
    blockEvent: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: _blockEvent(
        mediaSchema.endpoint,
        '/api/event_block_thumbs',
        '/api/event_block_multi_sections',
        '/api/event_block_multi_section_thumbs',
        '/api/event_block_branches',
        '/api/event_block_didascalias',
        '/api/event_block_contacts'
      ).types,
    },
    blockSpectacle: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: _blockSpectacle(
        mediaSchema.endpoint,
        '/api/event_block_thumbs',
        '/api/event_block_multi_sections',
        '/api/event_block_multi_section_thumbs',
        '/api/event_block_branches',
        '/api/event_block_didascalias',
        '/api/event_block_contacts'
      ).types,
    },
    terms: {
      //   endpoint: '/api/event_terms',
      //   definition: '#EventTerm',
      //   properties: {
      //     dateFrom: {
      //       type: 'datetime',
      //       description: 'Data i czas terminu',
      //       validate: ['required'],
      //     },
      //     recipients: {
      //       type: 'multipleResource',
      //       endpoint: `/api/event_recipients?pagination=false`,
      //       titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      //       label: 'T_MODULE_EVENT_RECIPIENTS_SELECT',
      //       hint: function () { return <Alert severity="info" style={{ background:'none', padding: 0 }} >można wybrać maksymalnie dwa udogodnienia</Alert> }
      //     },
      //   },
    },
    images: {
      endpoint: '/api/event_images',
      definition: '#EventImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Autor zdjęcia',
              hint: 'jesli brak, autor domyślny jak wyżej w sekcji tłumaczeń',
            },
            description: {
              type: 'textarea',
              description: 'Opis zdjęcia',
            },
            alt: {
              description: 'Alt',
              type: 'string',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoSchema.endpoint.single,
          endpointMultiple: photoSchema.endpoint.multiple,
          thumbs: {
            endpoint: '/api/event_image_thumbs',
          },
          validate: ['maxSize'],
        },
      },
    },
    videos: {
      endpoint: '/api/event_videos',
      definition: '#EventVideo',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
            },
            text: {
              type: 'textarea',
              description: 'Opis',
            },
            ytCode: {
              description: 'Kod z youtube',
            },

            audiodescriptionfile: {
              type: 'file',
              description: 'Audiodeskrypcja - plik audio',
              endpoint: mediaSchema.endpoint,
              validate: ['maxSize'],
              accept: 'audio/*',
            },
            audiodescriptionbutton: {
              description: 'Audiodeskrypcja - link',
              hint: 'link, jeśli brak pliku audio, można wpisać',
              type: 'string',
            },
            signlanguagebutton: {
              description: 'Tłumacz migowy',
            },
            text2: {
              description: 'Transkrypcja',
              type: 'textarea',
            },
          },
        },
      },
    },
    pricelists: {
      endpoint: '/api/event_pricelists',
      definition: '#EventPricelist',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            titlePricelist1: {
              description: 'Tytuł cennika 1',
            },
            titlePricelist2: {
              description: 'Tytuł cennika 2',
            },
            text: {
              description: 'Dodatkowe informacje - pod cennikiem',
              type: 'textarea',
            },
            fileTitle: {
              description: 'Nazwa pliku do pobrania',
              hint: function() {
                return (
                  <Alert
                    severity="warning"
                    style={{ background: 'none', padding: 0 }}
                  >
                    jeśli wybrany plik widowni, nazwa jest wymagana
                  </Alert>
                )
              },
            },
          },
        },

        // pricelist1: {
        //   type: 'resource',
        //   endpoint: `/api/package_pricelists?pagination=false&embeded=false`,
        //   titleAccessor: `title`,
        //   label: 'Cennik 1',
        // },
        // pricelist2: {
        //   type: 'resource',
        //   endpoint: `/api/package_pricelists?pagination=false&embeded=false`,
        //   titleAccessor: `title`,
        //   label: 'Cennik 2',
        // },

        pricelist1: {
          type: 'collectionpackageembeded',
          label: 'Cennik 1',
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          definitionRef: '#PackagePricelist',
          endpoint: '/api/package_pricelists',
          properties: {
            items: {
              type: 'embeddedCollection',
              label: ' ',
              titleAccessor: `translations.${LANGS_IRI.PL}.title`,
              endpoint: '/api/package_pricelist_items',
              definition: '#PackagePricelistItem',
              headerTitle: 'Wiersze cennika',
              expandTitle: 'wiersze',
              panelTitle: 'Wiersz',
              statable: true,
              properties: {
                translations: {
                  type: 'translation',
                  properties: {
                    strefe: {
                      description: 'Strefa',
                      hint: 'strefy o tych samych nazwach są grupowane w jedną',
                      type: 'string',
                    },
                    title: {
                      description: 'Tytuł/Bilet',
                      type: 'string',
                    },
                    price: {
                      description: 'Cena',
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        pricelist2: {
          type: 'collectionpackageembeded',
          label: 'Cennik 2',
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          definitionRef: '#PackagePricelist',
          endpoint: '/api/package_pricelists',
          properties: {
            items: {
              type: 'embeddedCollection',
              label: ' ',
              titleAccessor: `translations.${LANGS_IRI.PL}.title`,
              endpoint: '/api/package_pricelist_items',
              definition: '#PackagePricelistItem',
              headerTitle: 'Wiersze cennika',
              expandTitle: 'wiersze',
              panelTitle: 'Wiersz',
              statable: true,
              properties: {
                translations: {
                  type: 'translation',
                  properties: {
                    strefe: {
                      description: 'Strefa',
                      hint: 'strefy o tych samych nazwach są grupowane w jedną',
                      type: 'string',
                    },
                    title: {
                      description: 'Tytuł/Bilet',
                      type: 'string',
                    },
                    price: {
                      description: 'Cena',
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },

        media: {
          type: 'file',
          description: 'Plik widowni',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize'],
          hint: 'jesli wybrany plik, nazwa pliku wyżej jest wymagana',
        },
      },
    },
    avards: {
      endpoint: '/api/event_avards',
      definition: '#EventAvard',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł zdjęcia',
            },
            link: {
              description: 'Link',
            },
            alt: {
              description: 'Alt',
              type: 'string',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoSchema.endpoint.single,
          endpointMultiple: photoSchema.endpoint.multiple,
          thumbs: {
            endpoint: '/api/event_avard_thumbs',
          },
          validate: ['maxSize'],
        },
      },
    },
    friends: {
      endpoint: '/api/event_to_events',
      definition: '#EventToEvent',
      properties: {
        eventItem: {
          type: 'autoComplete',
          endpoint: `/api/events_select?archive=false`,
          titleAccessor: `nativeTitle`,
          placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
          label: 'Powiązane wydarzenia i spektakle',
        },
        hint: {
          type: 'hint',
          hint: function() {
            return (
              <Alert severity="warning">
                wydarzenia/spektakle, które przejdą w stan archiwalne, nie
                wyswietlą się
                <br />
              </Alert>
            )
          },
        },
      },
    },
  },
}

export default schema
