import {
  text,
  textWithPhoto,
  textWithLink,
  gallery,
  files,
  logotypes,
  audio,
  citation,
  textWithVideo,
  pages,
  events,
  pagesTile,
  homeLabirynth,
  contact,
  contactPerson,
  news,
  scenes,
  faq,
  a11string,
  a11text,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint,
  blockBranchesEndpoint,
  blockDidascaliaEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    textWithLink(
      photoEndpoint,
      blockThumbEndpoint + '?name[]=home_section_small&name[]=home_section'
    ),
    pages(blockMultiSectionEndpoint),
    gallery(),
    files(),
    logotypes(blockMultiSectionEndpoint),
    audio(photoEndpoint),
    citation(),
    events(blockMultiSectionEndpoint),
    news(blockMultiSectionEndpoint, '#PageBlockMultiSection', true),
    faq(),

    //tylko w edukacji
    pagesTile(
      blockMultiSectionEndpoint,
      photoEndpoint,
      blockMultiSectionThumbEndpoint
    ),
    //tylko w edukacji
    homeLabirynth(
      blockMultiSectionEndpoint,
      photoEndpoint,
      blockMultiSectionThumbEndpoint +
        '?name[]=home_section_small&name[]=home_section',
      '#PageBlockMultiSection',
      'Blok "W labiryncie teatru"',
      true
    ),

    //tylko w kontakt
    contact(blockContactEndpoint),
    //tylko w kontakt,teatr
    contactPerson(blockMultiSectionEndpoint),
    //tylko w teatr
    scenes(photoEndpoint, blockMultiSectionThumbEndpoint, blockMultiSectionEndpoint),

    //tylko strona deklaracji
    a11string(),
    a11text(),
  ],
})

export default schema
