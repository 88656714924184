import photoSchema from './photo'

const schema = {
  endpoint: '/api/crew_items',
  access: 'crewItem',
  resource: {
    definition: '#CrewItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          name: {
            description: 'Imię'
          },
          surname: {
            description: 'Nazwisko',
            validate: ['required']
          },
          text:{
            description: 'Biogram',
            type: 'tinymce'
          },
          job: {
            type:'string',
            description: 'Stanowisko osoby'
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          photoDescription: {
            type:'string',
            description: 'Podpis zdjęcia, np autor zdjęcia'
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/crew_item_thumbs',
        },
        validate: ['maxSize'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
