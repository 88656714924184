import photoSchema from './photo'
import eventSchema from './event'

const schema = {
  endpoint: '/api/home_banners',
  access: 'homeBanner',
  resource: {
    definition: '#HomeBanner',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          title2: {},
          director: {
            description: 'Reżyser',
          },
          autor: {
            description: 'Autor',
          },
          dateText: {
            description: 'Data',
          },
          buylink:{
            description: 'Link do "Kup bilet"'
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/home_banner_thumbs',
        },
        validate: ['maxSize'],
      },
      event: {
        type: 'autoComplete',
        titleAccessor: `nativeTitle`,
        endpoint: eventSchema.select_endpoint,
        placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        label: 'Wydarzenie/Spektakl',
      },
      premiere: {
        description: 'Pokaż label Premiera',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
