import React from 'react'
import { Alert } from '@material-ui/lab'
import { LANGS_IRI } from '_lib/langs'

const schema = {
  endpoint: '/api/event_terms',
  clone_endpoint: '/api/event_terms/clone',
  access: 'event',
  resource: {
    definition: '#EventTerm',
    properties: {
      bilety24Id: {
        description: 'ID z Bilety24',
        hint: 'nie wymagane, domyślnie 0',
        defaultValue: 0,
      },

      dateFrom: {
        type: 'datetime',
        description: 'Data i czas terminu',
        validate: ['required'],
      },

      recipients: {
        type: 'multipleResource',
        endpoint: `/api/event_recipients?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_RECIPIENTS_SELECT',
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              można wybrać maksymalnie dwa udogodnienia
            </Alert>
          )
        },
      },

      buttonType: {
        type: 'resource',
        endpoint: `/api/buy_button_types?pagination=false`,
        titleAccessor: `trans`,
        label: 'Guzik, typ',
        transTitle: true,
        validate: ['required'],
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              aktualny status guzika, aktualizowany w zależnośći od ilośći
              dostępnych miejsc
            </Alert>
          )
        },
      },

      category: {
        type: 'resource',
        endpoint: `/api/event_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORIES',
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              jeśli ID wpisany, kategoria może być zsynchronizowana i wybrana
              przy importowaniu terminu
            </Alert>
          )
        },
      },

      availableLeft: {
        description: 'Ilość z Bilety24',
        defaultValue: 0,
        validate: ['required'],
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              jeśli ID wpisany, ilość będzie sie aktualizować, domyślnie można
              ustawić dowolną ilość gdy typ guzika "Kup bilet"
            </Alert>
          )
        },
      },

      buyUrl: {
        description: 'Link do kup bilet',
        hint: function() {
          return (
            <Alert severity="info" style={{ background: 'none', padding: 0 }}>
              jeśli ID wpisany, link jest aktualizowany z Bilety24
            </Alert>
          )
        },
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
