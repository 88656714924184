const schema = {
  endpoint: '/api/cookie_ips',
  resource: {
    definition: '#CookieIp',
    properties: {

      ip: {
        label: 'IP',
        disable: 'true'
      },

      userAgent: {
        label: 'Przeglądarka',
        type: 'textarea',
        disable: true,
      },

      cookies: {
        label: 'Zapisane cookies',
        type: 'textarea',
        disable: true,
      },

      referer: {
        label: 'Skąd akcja',
        type: 'string',
        disable: true,
      },

    },
  },
}

export default schema
