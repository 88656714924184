import {
  text,
  textWithPhoto,
  textWithLink,
  gallery,
  files,
  logotypes,
  audio,
  citation,
  textWithVideo,
  pages,
  events,
  news,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint,
  blockBranchesEndpoint,
  blockDidascaliaEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    textWithLink(
      photoEndpoint,
      blockThumbEndpoint + '?name[]=home_section_small&name[]=home_section'
    ),
    pages(blockMultiSectionEndpoint, '#NewsBlockMultiSection'),
    gallery(),
    files(),
    logotypes(blockMultiSectionEndpoint, '#NewsBlockMultiSection'),
    audio(photoEndpoint),
    citation(),
    events(blockMultiSectionEndpoint, '#NewsBlockMultiSection', true),
    news(blockMultiSectionEndpoint, '#NewsBlockMultiSection', true),
  ],
})

export default schema
