import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined'
import { random } from 'lodash'

export const YtWithShtType = ({ mainLabel }) => {
  const [show, setShow] = useState(false)
  const tagkey = `js-videowizytowka-${random(0,10000)}`

  const handleClick = () => {
    const xshow = !show
    var opc = xshow ? 'block' : 'none'
    var parent = document.querySelector(`.${tagkey}`)
    var all = parent.closest('div[role="tabpanel"]').querySelectorAll('*[data-hide="videowizytowka-hide"]')
    all.forEach(el => {
      el.style.display = opc
    })
    setShow(xshow)
  }

  useEffect(() => {
    var opc = 'none'
    var parent = document.querySelector(`.${tagkey}`)
    var all = parent.closest('div[role="tabpanel"]').querySelectorAll('*[data-hide="videowizytowka-hide"]')
    all.forEach(el => {
      el.style.display = opc
    })
  }, [])

  return (
    <div class={tagkey}>
      <Button
        onClick={handleClick}
        color="primary"
        size="small"
        style={{ paddingLeft: 0, xmargin: 0 }}
      >
        <OndemandVideoOutlinedIcon style={{ marginRight: 10 }} />
        {mainLabel}
      </Button>
    </div>
  )
}

YtWithShtType.propTypes = {}

/*
import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import {
  InputLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { CreateOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { translate } from '_helpers/translate'
import { Form } from 'pages'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    marginBottom: 15,
  },
}))

export const YtWithShtType = ({
  label,
  definitionRef = null,
  endpoint,
  properties,
  classes = {},
  translationLang,
  ...rest
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? schema.definitions[definitionRef] : schema),
    [schema, definitionRef]
  )

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const defaultClasses = useStyles()

  const resource = rest.getValue('translations')[translationLang]
  const iri = resource['@id']

  const shemaT = {
    definition: '#CrewItem',
    properties: properties,
  }

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <InputLabel className={clsx(defaultClasses.label, classes.label)}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Tooltip
        title={translate(
          iri ? 'T_GENERAL_EDIT' : 'T_GENERAL_SAVE_FORM_TO_EDIT'
        )}
      >
        <span>
          <IconButton color="primary" onClick={handleToggle} xdisabled={!iri}>
            <CreateOutlined
              className={clsx(defaultClasses.labelButton, classes.labelButton)}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        onClose={handleToggle}
        open={isOpen}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitleWithClose onClose={handleToggle}>
          {translate(label.text || label)}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
        >
          <Form
            header={() => ''}
            wrapWithPaper={false}
            definitionSchema={definitionSchema}
            customResourceSchema={shemaT}
            iri={iri}
            method="PUT"
            url={iri}
            storeCollectionId={iri + '-form'}
            sidebar={false}
            buttonsFixed={false}
            showSubmitAndStayButton={false}
            showCancelButton={false}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

YtWithShtType.propTypes = {}

*/
