import adminSchema from '_schema/admin'
import adminGroupSchema from '_schema/adminGroup'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'

import event from '_schema/event'
import eventCategory from '_schema/eventCategory'
import eventLocations from '_schema/eventLocations'
import eventRecipients from '_schema/eventRecipients'
import eventCategoryCycle from '_schema/eventCategoryCycle'
import homeBanner from '_schema/homeBanner'
import news from '_schema/news'
import newsCategory from '_schema/newsCategory'
import packageFaq from '_schema/packageFaq'
import packageFile from '_schema/packageFile'
import packageGallery from '_schema/packageGallery'
import packageLogotype from '_schema/packageLogotype'
import practicalInformation from '_schema/practicalInformation'
import offer from '_schema/offer'
import footMenu from '_schema/footMenu'
import footLogotype from '_schema/footLogotype'
import footAddrr from '_schema/footAddress'
import blackList from '_schema/blackList'

const resources = () => ({
  [page.access]: 'Struktura stron i sekcje strony głównej',
  [adminSchema.access]: 'Administrators',
  [adminGroupSchema.access]: 'Administrator Groups',
  [loginEntry.access]: 'Login entries',
  [blackList.access] : 'Lista nieudanych logowań',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  [event.access]: 'Wydarzenia i Spektakle',
  [eventCategory.access]: 'Wydarzenia - kategorie',
  [eventLocations.access]: 'Wydarzenia - miejsca',
  [eventRecipients.access]: 'Wydarzenia - udogodnienia',
  [eventCategoryCycle.access]: 'Wydarzenia - cykle kategorie',
  [homeBanner.access]: 'Banner główny',
  [footMenu.access]: 'Stopka menu',
  [footLogotype.access]: 'Stopka logotypy',
  [footAddrr.access]: 'Stopka miejsca',
  [news.access]: 'Aktualności',
  [newsCategory.access]: 'Aktualności - kategorie',
  [packageFile.access]: 'Zestawy plików',
  [packageGallery.access]: 'Galerie',
  [packageLogotype.access]: 'Pakiety logotypów',
  [packageFaq.access]: 'Zestawy FAQ',
  [practicalInformation.access]: 'Oferty specjalne',
  [offer.access]: 'Oferty',
})

export default resources
