import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
// import { useSelector } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
// import { CloneCell } from 'components/table/cells/CloneCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
// import { commonConstants } from '_constants'
import routes from 'pages/FootMenu/routes'
import clsx from 'clsx'
// import red from '@material-ui/core/colors/red'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const operations = (
  accessor = `translations.${process.env.REACT_APP_LOCALE}.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneable = true
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    // const profile = useSelector(state => state.profile)

    // const dispatch = useDispatch()

    const onSuccess = () => {
      // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    const isSubPageable = false //resource.parent ? false : true

    const showMenu = true

    return (
      <div className={classes.root}>
        {showMenu && (
          <div>
            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx(
                classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    // disabled={!profile.isOpenform && !resource.isEditable}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {isSubPageable && (
                <MenuItem className={classes.menuItem}>
                  <Link
                    to={routes().newParent.path.replace(
                      ':pid',
                      `${resource.uuid}`
                    )}
                    className={classes.link}
                  >
                    {translate('T_MODULE_PAGES_ADD_SUBPAGE')}
                    {/* <span style={{color: red[800]}}>{ (profile.isOpenform && (!resource.isSubPageable || !(resource.nestingLevel < maxNesting)  )) ? ' [OF]' : '' }</span> */}
                  </Link>
                </MenuItem>
              )}
              {isSubPageable && (
                <MenuItem className={classes.menuItem}>
                  <Link
                    to={routes().indexParent.path.replace(
                      ':id',
                      `${resource.uuid}`
                    )}
                    className={classes.link}
                  >
                    {translate('Podstrony')}
                    {/* <span style={{color: red[800]}}>{ (profile.isOpenform && (!resource.isSubPageable || !(resource.nestingLevel < maxNesting)  )) ? ' [OF]' : '' }</span> */}
                  </Link>
                </MenuItem>
              )}

              {/* {cloneable && (
                <MenuItem className={classes.menuItem}>
                  <CloneCell
                    resource={resource}
                    disabled={!!resource.idName}
                    onSuccess={onSuccess}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )} */}

              <MenuItem className={classes.menuItem}>
                <DeleteCell
                  resource={resource}
                  accessor={accessor}
                  // disabled={!profile.isOpenform && !resource.isDeletable}
                  onSuccess={onSuccess}
                  tableState={tableState}
                  tableStateDispatch={tableStateDispatch}
                />
              </MenuItem>
            </StyledMenu>
          </div>
        )}
        {1 && (
          <StatusCell
            resource={resource}
            // disabled={!profile.isOpenform && !resource.isStatable}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
