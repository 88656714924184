import _blockBip from './_blockBip'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/bip_pages',
  clone_endpoint: '/api/bip_pages/clone',
  version_endpoint: '/api/bip_pages/version',
  access: 'bipPage',
  resource: {
    definition: '#BipPage',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          preview:{
            type: 'bipPreview',
            routes: {
              pl: '/bip/wersja/{uuid}',
              en: '/{_locale}/bip/version/{uuid}',
            },
            description: 'Link poglądowy wersji'
          }
        },
      },

      creatorName: {
        description: 'Informację wytworzył',
      },
      personResponsible: {
        description: 'Informację opublikował',
      },
      expandMenu: {
        description: 'Pokaż podstrony w menu',
      },
      openIfChildren: {
        description: 'Otwieraj w menu, gdy podstrony',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/bip_page_blocks',
      definition: '#BipPageBlock',
      types: _blockBip(
        mediaSchema.endpoint,
        '/api/bip_page_block_thumbs',
        '/api/bip_page_block_files',
        '/api/bip_page_block_contacts'
      ).types,
    },
  },
}

export default schema
