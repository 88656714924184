import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'pages/BlankForm'
import { Loader } from 'components/Loader'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { commonConstants } from '_constants'
import { NotFound } from 'pages/NotFound'
import { boolean } from '_helpers/boolean'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const Form = ({
  iri = null,
  isNotEditable: verifyIsNotEditable = null,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  isCurrentResource = true,
  wrapWithPaper = false,
  header = null,
  sidebar = false,
  sidebarTitleAccessor = 'title',
  sidebarStatAccessor = 'stat',
  sidebarVersionEndpoint = '',
  onVersionSuccess = null,
  versionDisabled = null,
  SidebarComponent = null,
  children = null,
  setParentResource = null,
  ...rest
}) => {
  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [isCurrentResource, dispatch]
  )

  const { resource, isFetching, isNotEditable, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    verifyIsNotEditable,
    setState.isNotEditable,
    dispatchOnFetch
  )

  useEffect(() => {
    if (resource && isCurrentResource && isNotEditable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }

    if (setParentResource && resource) {
      setParentResource(resource)
    }
  }, [resource, isCurrentResource, isNotEditable, dispatch, setParentResource])

  const calculatedCollectionPath = useMemo(
    () =>
      collectionPath
        ? typeof collectionPath === 'function'
          ? resource
            ? collectionPath(resource)
            : null
          : collectionPath
        : null,
    [collectionPath, resource]
  )

  const calculatedStoreCollectionId = useMemo(
    () =>
      storeCollectionId
        ? typeof storeCollectionId === 'function'
          ? resource
            ? storeCollectionId(resource)
            : null
          : storeCollectionId
        : null,
    [storeCollectionId, resource]
  )

  const classes = useStyles()

  return isFetching ? (
    <div>
      <Loader />
    </div>
  ) : isNotEditable ? (
    <Redirect to={calculatedCollectionPath} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      <BlankForm
        resource={resource}
        iri={iri}
        collectionPath={calculatedCollectionPath}
        editPath={editPath}
        storeCollectionId={calculatedStoreCollectionId}
        isCurrentResource={isCurrentResource}
        buttonsFixed={false}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fetchCompareResource={boolean(
          process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
        )}
        {...rest}
      />
      {typeof children === 'function' ? children(resource) : children}
    </div>
  )
}

Form.propTypes = {}
