import _block from './_block'
import mediaSchema from './media'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/pages',
  clone_endpoint: '/api/pages/clone',
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          shortDescription: {
            description: 'Krótki opis',
            label: 'Krótki opis',
            type: 'textarea',
            hint: 'wykorzystywany na kafelkach z przekierowaniami'
          },
          // link: {
          //   description: 'T_GENERAL_EXTERNAL_LINK',
          // },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          photoDescription: {
            type: 'textarea',
            description: 'Opis pod zdjęciem',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/page_thumbs',
          property: 'media',
        },
      },
      // tylko dla stron teatr + plav_visti
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/page_thumbs',
          property: 'mediaDetail',
        },
      },

      // mediaThumbType: {
      //   type: 'resource',
      //   endpoint: `/api/thumb_types?pagination=false`,
      //   titleAccessor: `trans`,
      //   transTitle: true,
      //   label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
      //   initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
      //   validate: ['required'],
      // },
      // hideDetailImage: {
      //   description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      // },
      // mediaDetail: {
      //   type: 'image',
      //   description: 'T_GENERAL_PHOTO_DETAIL',
      //   endpoint: photoSchema.endpoint.single,
      //   validate: ['maxSize'],
      //   thumbs: {
      //     endpoint: '/api/page_thumbs',
      //     property: 'mediaDetail',
      //   },
      // },
      stat: {},
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/page_block_thumbs',
        '/api/page_block_multi_sections',
        '/api/page_block_multi_section_thumbs',
        '/api/page_block_branches',
        '/api/page_block_didascalias',
        '/api/page_block_contacts'
      ).types,
    },
  },
}

export default schema
