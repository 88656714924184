import React, { useState } from 'react'
import { constants } from 'components/table/_state'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import { notification } from '_helpers/notification'
import { IconButton } from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { prop } from '_helpers/prop'

export const FlagCell = ({
  resource,
  accessor = 'stat',
  disabled = false,
  tableStateDispatch,
}) => {
  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  const [status, setStatus] = useState(prop(resource, accessor))

  const handleChange = () => {
    if (disabled) {
      return
    }

    setStatus(!status)
    fetchDataHandleAuthError(
      resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: !status,
        }),
      },
      resource => {
        // handleSyncUpdatedAt && handleSyncUpdatedAt(resource)
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(!status)
      },
      error => {
        setStatus(status)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <IconButton onClick={handleChange}>
      {status ? <StarOutlinedIcon /> : <StarBorderOutlinedIcon />}
    </IconButton>
  )
}

FlagCell.propTypes = {}
