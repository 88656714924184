import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const a11string = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.A11_STRING,
  label: labelTrans ?? '',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        text: {
          description: 'Tekst',
          type: 'textarea',
        },
      },
    },
  },
})
