import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './_helpers/_operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/cookieItem'
import routes from './routes'
import schema2 from '_schema/config'
import { LANGS_IRI } from '_lib/langs'
import { Form } from './_helpers/Form'

export const Collection = ({ schema: definitions }) => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        width: '73%',
      },

      {
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '15%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj grupę')}
        buttonPath={routes().new.path}
      >
        {translate('Cookies')}

        <div style={{ fontSize: 'initial', marginTop: '2em' }}>
          <Form
            definitionSchema={definitions[schema2.resource.definition]}
            customResourceSchema={schema2.resourceforcookies}
            iri={schema2.endpoint}
            method="PUT"
            url={schema2.endpoint}
            storeCollectionId={schema2.endpoint}
            sidebar={false}
            width={700}
          ></Form>
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        definition={schema.resource.definition}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
