import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'
import { Alert } from '@material-ui/lab'

export const pagesTile = (
  blockMultiSectionEndpoint,
  photoEndpoint,
  blockMultiSectionThumbEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.PAGES_TILE,
  label: 'Blok z przekierowaniami do artykułów',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Strony',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`, //'pageTitle',
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {
        event: {
          type: 'autoComplete',
          endpoint: `/api/events_select?archive=false`,
          titleAccessor: `nativeTitle`,
          label: 'Wybierz wydarzenie/spektakl',
          placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        },

        page: {
          type: 'autoComplete',
          endpoint: `/api/pages_select?exists[versionable]=false`,
          titleAccessor: `translations.title`,
          arrayTitleAccessor: [`translations.${LANGS_IRI.PL}.title`, null],
          label: 'lub wybierz stronę',
          placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        },

        hint: {
          type: 'hint',
          hint: function() {
            return (
              <Alert severity="warning">
                wydarzenia/spektakle, które przejdą w stan archiwalne, nie wyswietlą się
                <br />
              </Alert>
            )
          },
        },
        hint2: {
          type: 'hint',
          hint: function() {
            return (
              <Alert severity="info">
                użyj poniższych pól aby nadpisać informacje z wybranej strony/wydarzenia/spektaklu
                <br />
              </Alert>
            )
          },
        },

        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
              type: 'string',
              hint: 'zastępuje tytuł strony/wydarzenia/spektaklu'
            },
            description: {
              description: 'Krótki opis pod kaflem',
              type: 'textarea',
              hint: 'zastępuje opis strony/wydarzenia/spektaklu'
            },
            alt: {
              description: 'Tag alt',
              type: 'string',
              hint: 'zastępuje alt strony/wydarzenia/spektaklu'
            },
            link: {
              description: 'Link',
              hint: 'zastępuje link do strony/wydarzenia/spektaklu',
              type: 'string',
            },
          },
        },

        media2: {
          type: 'media[image]',
          description: 'Zastępuje zdjęcie strony/wydarzenia/spektaklu', //'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoEndpoint,
          thumbs: blockMultiSectionThumbEndpoint && {
            endpoint: blockMultiSectionThumbEndpoint + '?name=home_section_small',
          },
          validate: ['maxSize'],
        },
      },
    },
  },
})
