const schema = {
  endpoint: '/api/cookie_items',
  resourceforadd: {
    definition: '#CookieItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            label: 'Nazwa grupy',
          },
          shortLead: {
            type: 'textarea',
          },
        },
      },
    },
  },
  resource: {
    definition: '#CookieItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa grupy',
          },
          shortLead: {
            type: 'textarea',
          },
        },
      },

      required: {},
      stat: {},
      // idName: {},

      script: {
        type: 'textarea',
      },
    },
  },
  subresources: {
    items: {
      endpoint: '/api/cookie_item_options',
      definition: '#CookieItemOption',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            provider: {},
            shortLead: {
              type: 'textarea',
            },
            date: {},
            kind: {},
          },
        },

        stat: {},
      },
    },
  },
}

export default schema
