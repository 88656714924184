import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles({
  input: {
    textAlign: 'left',
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const YesNoFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
}) => {
  const [open, setOpen] = React.useState(false)

  const handleChange = e => {
    const value = e.target.value
    setFilter(name, value || null)
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleEnter && handleEnter()
        break
      default:
        break
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const defaultClasses = useStyles()

  return (
    <div>
      <FormControl className={clsx(defaultClasses.input, classes.input)}>
        {/* <InputLabel id={`demo-controlled-open-select-label-${name}`}>{title}</InputLabel> */}
        <Select
          labelId={`demo-controlled-open-select-label-${name}`}
          id={`demo-controlled-open-select-id-${name}`}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value || ''}
          onChange={handleChange}
          name={`filter-text-${name}`}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        >
          <MenuItem value="">
            <em>wszystkie</em>
          </MenuItem>
          <MenuItem value={'true'}>Tak</MenuItem>
          <MenuItem value={'false'}>Nie</MenuItem>
        </Select>
        <FormHelperText>{title}</FormHelperText>
      </FormControl>
    </div>
  )
}

YesNoFilter.propTypes = {}
