import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eventCategoryCycle'
import routes from './routes'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const profile = useSelector(state => state.profile)

  const addOpenformProperties = (isOpenform, properties) => {
    const modifiedProperties = properties

    if (isOpenform) {
      return {
        ...properties,
        idName: {
          type: 'string',
          description: {
            text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
            color: '#cc0000',
            type: 'string',
          },
        },
      }
    }

    return modifiedProperties
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={'100%'}
      modifyProperties={properties =>
        addOpenformProperties(profile.openform, properties)
      }
    ></Form>
  )
}
