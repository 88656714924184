import React from 'react'
import { Alert } from '@material-ui/lab'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeEvents = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.HOME_EVENTS,
  label: 'Sekcja z Wydarzeniami i Spektaklami',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł sekcji',
        },
        subtitle: {
          type: 'string',
          label: 'Nadtytuł sekcji',
        },
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Wydarzenia i spektakle',
      titleAccessor: 'eventTitle', //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {
        event: {
          type: 'autoComplete',
          endpoint: `/api/events_select?archive=false`,
          titleAccessor: `nativeTitle`,
          label: 'T_GENERAL_LINKED_EVENTS',
          placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        },
        hint: {
          type: 'hint',
          hint: function() {
            return (
              <Alert severity="warning">
                wydarzenia/spektakle, które przejdą w stan archiwalne, nie
                wyswietlą się
                <br />
              </Alert>
            )
          },
        },
      },
    },
  },
})
