import photoSchema from './photo'

const schema = {
  endpoint: '/api/foot_logotypes',
  access: 'footLogotype',
  resource: {
    definition: '#FootLogotype',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'widoczny tylko w PA',
          },
          // description: {
          //   description: 'Opis pod logotypem',
          // },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/foot_logotype_thumbs',
        },
        validate: ['maxSize'],
      },

      ue: {
        description: 'Logo unieuropejskiej',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
