import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { translate } from '_helpers/translate'
import schema from '_schema/blackList'
import { StatusCell } from './table/cells/StatusCell'
import { DateFilter } from 'components/table/filters/DateFilter'
import { YesNoFilter } from 'components/table/filters/YesNoFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('login'),
        header: translate('Próbowany login'),
      },
      {
        ...standard('createdAt'),
        width: '25%',
        header: translate('T_LOGIN_DATE'),
        Cell: DatetimeCell,
      },
      {
        ...standard('ip'),
        width: '20%',
      },
      {
        ...standard('blocked'),
        width: '5%',
        Cell: StatusCell,
        accessor: 'blocked',
      },
    ],
    []
  )

  const customFilters = [
    {
      name: 'createdAt[after]',
      title: 'Data od',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'createdAt[before]',
      title: 'Data do',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'blocked',
      title: 'Pokazuj blokowane',
      value: null,
      Filter: YesNoFilter,
    },
    {
      name: 'groupedby',
      title: 'Grupuj',
      value: true,
      Filter: YesNoFilter,
    },
  ]

  return (
    <Paper>
      <Title>
        {translate('Lista IP, nieudane próby logowania ponad 3 razy na minutę')}
      </Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        customFilters={customFilters}
        // defaultFilters={{
        //   groupedby: 1,
        // }}

        parameters={[
          {
            name: 'groupedby',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'login',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[login]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[blocked]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt[after]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt[before]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'blocked',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
