import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import itemSchemaBilety from '_schema/eventTermBilety24'
import { notification } from '_helpers/notification'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { translate } from '_helpers/translate'
import { Loader } from 'components/Loader'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const OpCell = ({
  resource,
  parentResource,
  accessor = null,
  isRowLinkable = false,
  editPath = null,
  ...rest
}) => {
  // const author = resource.translations[LANGS_IRI.PL]?.author
  // const description = resource.translations[LANGS_IRI.PL]?.description

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const classes = useStyles()

  const onImportClick = () => {
    setState(state => {
      return { ...state, isFetching: true }
    })
    fetchDataHandleAuthError(
      itemSchemaBilety.endpointAdd.replace('{id}', resource.titleId),
      'POST',
      {
        body: JSON.stringify({
          termId: resource.id,
          date: resource.date,
          titleId: resource.titleId,
          uuid: parentResource.uuid,
          category: resource.category,
          url: resource.url,
          availableLeft: resource.availableLeft,
        }),
      },
      response => {
        setState(state => {
          return { ...state, isFetching: false }
        })
        notification(
          'success',
          response.terminExists
            ? 'Termin już istnieje, ID został dodany i powiązany z terminem.'
            : 'Termin został zaimportowany pomyślnie',
          'T_FORM_SUCCESS'
        )
        rest.useForeignDispatch()
      },
      error => {
        setState(state => {
          return { ...state, isFetching: false }
        })
        notification(
          'error',
          error.response.violations.length
            ? error.response.violations[0].message
            : error.response.detail,
          error.response.title
        )
      }
    )
  }

  const handleOpen = () => {
    setState(state => {
      return { ...state, isConfirmOpen: !state.isConfirmOpen }
    })
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.button}
        startIcon={<AddCircleIcon />}
        onClick={() => handleOpen()}
        disabled={state.isFetching}
      >
        Importuj
      </Button>
      <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleOpen}>
          Import terminu wydarzenia
        </DialogTitle>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <DialogContentText>
            Czy napewno chcesz importować termin ? Istniejący terminy z taką
            samą datą otrzymaja importowany ID.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpen}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={() => {
              handleOpen()
              onImportClick()
            }}
            disabled={state.isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

OpCell.propTypes = {}
