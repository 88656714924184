// import packageLogotype from './packageLogotype'
// import packageFile from './packageFile'
// import photoSchema from './photo'
// import pageSchema from './page'
// import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_CONFIG_PAGE_TITLE',
            hint: 'widoczny w pasku/tabie przeglądarki',
          },
          footTitle: {
            description: '[STOPKA] Główny tytuł',
            type: 'textarea',
          },
          footAddress: {
            description: '[STOPKA] Główny adres',
            type: 'textarea',
          },
          footPhone: {
            description: '[STOPKA] Główny telefon',
            type: 'string',
          },
        },
      },
      additionalHeadCode: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },
      linkYoutube: {
        description: 'T_LINKS_YOUTUBE',
      },
      linkFacebook: {
        description: 'T_LINKS_FACEBOOK',
      },
      linkInstagram: {
        description: 'T_LINKS_INSTAGRAM',
      },
      linkTwitter: {
        description: 'Link Twitter',
      },
      linkLinkedIn: {
        description: 'Link LinkedIN',
      },

      // headerAccessibilityPage: {
      //   type: 'resource',
      //   endpoint: `${pageSchema.endpoint}?pagination=false`,
      //   titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      //   description: 'Strona dostępności w nagłówku',
      // },
      // logotypes: {
      //   type: 'resource',
      //   endpoint: `${packageLogotype.endpoint}?pagination=false`,
      //   titleAccessor: 'title',
      //   description: 'T_FOOTER_LOGOTYPES_SELECT',
      //   label: 'T_FOOTER_LOGOTYPES_SELECT',
      // },
      // suggestPurchaseMedia: {
      //   type: 'image',
      //   description: 'T_SECTION_SUGGEST_PURCHASE_MEDIA',
      //   endpoint: photoSchema.endpoint.single,
      //   endpointMultiple: photoSchema.endpoint.multiple,
      //   thumbs: {
      //     endpoint: '/api/config_thumbs',
      //   },
      //   validate: ['maxSize'],
      // },

      black: {
        description: 'Wersja żałobna',
      },
    },
  },

  // resourceforcookies:{
  //   definition: '#Config',
  //   properties: {
  //     translations: {
  //       type: 'translation',
  //       properties: {
  //         cookiesBoxTitle:{
  //           type: 'string',
  //           hint:'tytuł boxa'
  //         },
  //         cookiesBoxMsg: {
  //           type: 'textarea',
  //           hint:'główny tekst w boxie'
  //         },
  //       }
  //     }
  //   }
  // },
  resourceforcookies: {
    definition: '#Config',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_CONFIG_PAGE_TITLE',
          },
          cookiesBoxTitle: {
            description: 'Cookies tytuł',
            type: 'string',
            hint: 'tytuł boxa',
          },
          cookiesBoxMsg: {
            description: 'Cookies opis',
            type: 'textarea',
            hint: 'główny tekst w boxie',
          },
        },
      },
    },
  },
}

export default schema
