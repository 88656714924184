import { LANGS_IRI } from "_lib/langs"

const schema = {
  endpoint: '/api/foot_menus',
  access: 'footMenu',
  resource: {
    definition: '#FootMenu',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          titleEmpty: {
            label: 'Title',
            hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony',
            type: 'string',
          },
          link: {
            hint: 'wpisz link lub wybierz stronę poniżej',
          },
        },
      },

      page: {
        type: 'autoComplete',
        titleAccessor: `translations.title`,
        arrayTitleAccessor: [`translations.${LANGS_IRI.PL}.title`, null],
        endpoint: `/api/pages_select?pagination=false&exists[versionable]=false`,
        label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
        hint: 'Jeśli wpisany jest link, strona nie będzie brana pod uwagę',
        placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
