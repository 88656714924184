import React from 'react'
import { Route } from 'react-router-dom'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { New as NewT } from 'pages/EventTerm/New'
import { Edit as EditT } from 'pages/EventTerm/Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/event'
import { Terms } from '../EventTerm/Terms'

const basePath = '/events_archive'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_LIST',
        type: Route,
        render: props => <Collection type="event" {...props} />,
        path: basePath,
        exact: true,
        icon: AssignmentOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            type="event"
            component={New}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            type="event"
            component={Edit}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      terms: {
        subtitle: 'Terminy',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            type="event"
            archive={true}
            component={Terms}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/:id/terms`,
      },

      termsNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        parent: `${basePath}/:id`,
        render: props => (
          <SchemableComponent
            path="definitions"
            type="event"
            archive={true}
            component={NewT}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/:id/terms/new`,
        id: 'terms_edit',
      },

      termsEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        parent: `${basePath}`,
        render: props => (
          <SchemableComponent
            path="definitions"
            type="event"
            archive={true}
            component={EditT}
            {...props}
          />
        ),
        path: `${basePath}/:pid/terms/:id`,
        exact: true,
        id: 'terms_edit',
      },
    },
    {
      title: 'Wydarzenia - archiwum',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
