import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeCrew = (
  blockMultiSectionEndpoint,
  definitionRef = '#PageBlockMultiSection'
) => ({
  name: BLOCK_TYPES_IRI.HOME_CREW,
  label: 'Sekcja z zespołem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł sekcji',
        },
        subtitle: {
          type: 'string',
          label: 'Nadtytuł sekcji',
        },
      },
    },
  },
})
