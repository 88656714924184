import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'
import { Alert } from '@material-ui/lab'

export const homeLabirynth = (
  blockMultiSectionEndpoint,
  photoEndpoint,
  blockMultiSectionThumbEndpoint,
  definitionRef = '#PageBlockMultiSection',
  title = null,
  withAutomatic = false
) => {
  let out = {
    name: BLOCK_TYPES_IRI.HOME_LABIRYNTH,
    label: title ? title : 'Blok "W labiryncie teatru"',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            label: 'Tytuł sekcji',
          },
          text: {
            type: 'textarea',
            label: 'Tekst',
            description: 'Tekst',
          },
        },
      },
      multiSections: {
        type: 'collection',
        label: 'Kafle',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        definitionRef: definitionRef,
        endpoint: blockMultiSectionEndpoint,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                description: 'T_GENERAL_TITLE',
                label: 'T_GENERAL_TITLE',
                type: 'string',
              },
              description: {
                type: 'textarea',
                label: 'Tekst',
                description: 'Tekst',
                hint: function() {
                  return (
                    <Alert
                      severity="warning"
                      style={{ background: 'none', padding: 0 }}
                    >
                      tekst dostępny tylko w pierszym kaflu
                    </Alert>
                  )
                },
              },
              link: {
                type: 'string',
                description: 'T_GENERAL_BLOCK_LINK',
              },
              alt: {
                description: 'Tag alt',
                type: 'string',
              },
            },
          },

          media2: {
            type: 'image',
            description: 'T_GENERAL_MEDIA_PHOTO',
            endpoint: photoEndpoint,
            thumbs: blockMultiSectionThumbEndpoint && {
              endpoint: blockMultiSectionThumbEndpoint,
            },
            validate: ['maxSize'],
          },
        },
      },
    },
  }

  if (withAutomatic) {
    out = {
      ...out,
      properties: {
        ...out.properties,
        automatic: {
          label: 'Użyj bloku ze strony głównej',
          hint:
            'wyswietla ten sam blok co na stronie głównej, zamiast ręcznie wybranych',
        },
      },
    }
  }

  return out
}
