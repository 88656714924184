import React, { useEffect, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/eventTerm'
import schemaParent from '_schema/event'
import routesEvent from 'pages/Event/routes'
import routesSpectacle from 'pages/Event/routesSpectacle'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import routesEventArchive from 'pages/EventArchive/routes'
import routesSpectacleArchive from 'pages/EventArchive/routesSpectacle'

export const New = ({
  schema: definitions,
  archive = false,
  type,
  ...rest
}) => {
  const routes = archive
    ? type === 'event'
      ? routesEventArchive
      : routesSpectacleArchive
    : type === 'event'
    ? routesEvent
    : routesSpectacle

  const pid = rest.match.params.id
  const defaultData = pid ? { parent: `${schemaParent.endpoint}/${pid}` } : {}

  const dispatch = useDispatch()

  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  // zeby w bredcrumbsie rodzica było Wydarzenia(Tytuł wydarzenia)
  useEffect(
    () => {
      const controller = new AbortController()
      const { signal } = controller

      setState({
        ...state,
        isFetching: true,
        fetchError: false,
      })

      fetchDataHandleAuthError(
        `${schemaParent.endpoint}/${pid}`,
        'GET',
        { signal },
        response => {
          setState({
            isFetching: false,
            resource: response,
          })
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          notification('error', error.response.detail, error.response.title)
          setState({
            isFetching: false,
            fetchError: true,
          })
        }
      )

      return () => controller.abort()
    },
    // eslint-disable-next-line
    []
  )

  const { resource } = state

  useEffect(() => {
    if (resource) {
      dispatch({
        type: commonConstants.SET_CURRENT_PARENT_RESOURCE,
        payload: { resource: resource },
      })
    }
  }, [resource, dispatch])

  return (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().terms.path.replace(':id', pid)}
      editPath={routes().termsEdit.path.replace(':pid', pid)}
      storeCollectionId={schema.endpoint}
      width={600}
    />
  )
}
