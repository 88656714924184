export const modifyPropertiesByPage = (properties, resource, schema) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  switch (resource.idName) {
    // Modify by page here
    case 'teatr':
    case 'plan_visit':
      delete modifiedProperties.media
      return modifiedProperties
    case 'gallery':
    case 'special_offer':
    case 'job_offers':
    case 'headmaster':
    case 'contact':
    case 'howtoget':
      delete modifiedProperties.mediaDetail
      delete modifiedProperties.translations.properties.description
      return modifiedProperties
    case 'home':
      delete modifiedProperties.mediaDetail
      delete modifiedProperties.events
      delete modifiedProperties.news
      delete modifiedProperties.stat
      delete modifiedProperties.canOpen
      delete modifiedProperties.mainMenu
      delete modifiedProperties.translations.properties.description
      delete modifiedProperties.translations.properties.link
      delete modifiedProperties.translations.properties.slug
      return modifiedProperties
    case 'accessibility_declaration':
      delete modifiedProperties.translations.properties.description
      delete modifiedProperties.mediaDetail
      return modifiedProperties
    default:
      delete modifiedProperties.mediaDetail

      if (resource.parentIdName === 'gallery') {
        delete modifiedProperties.translations.properties.description
        return modifiedProperties
      }

      return modifiedProperties
  }
}
