import React from 'react'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import moment from 'moment'
import { Alert } from '@material-ui/lab'

export const harmonogram = (blockMultiSectionEndpoint, definitionRef) => ({
  name: BLOCK_TYPES_IRI.HARMONOGRAM,
  label: 'Blok z harmonogramem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Tytuł bloku',
          hint: 'np. Harmonogram, Zajęcia itp.',
        },
      },
    },
    hint: {
      type: 'hint',
      hint: function() {
        return (
          <Alert severity="info">
            blok harmonogram pokazuje wszstkie terminy cyklu oraz terminy
            zdefiniowane poniżej, kolejność jest po dacie
            <br />
            jeśli dodana data w terminach poniżej, jest taka sama jak w
            terminach cyklu, to ten termin traktowany jest jako jeden - można do
            niego dodać opis/wydarzenie
          </Alert>
        )
      },
    },
    multiSections: {
      type: 'collection',
      label: 'Terminy',
      titleAccessor: function(e) {
        return e.dateFrom ? moment(e.dateFrom).format('YYYY-MM-DD') : '--'
      }, //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRef,
      endpoint: blockMultiSectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            description: {
              description: 'Opis',
              type: 'textarea',
            },
          },
        },
        dateFrom: {
          type: 'datetime',
          description: 'Data i godzina',
          validate: ['required'],
        },
        event: {
          type: 'autoComplete',
          endpoint: `/api/events_select?type=event`,
          titleAccessor: `nativeTitle`,//`translations.${LANGS_IRI.PL}.title`, //
          label: 'Wydarzenie',
          hint: 'jeśli wybrane, zastępuje opis, tytułem wydarzenia',
          placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        },
        fake: {
          type: 'boolean',
          description: 'Bilet',
          hint: 'tu będzie podpinanie biletu',
        },
      },
    },
  },
})
