import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/mainMenu'
import routes from './routes'
import { Alert } from '@material-ui/lab'

import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { Loader } from 'components/Loader'

export const Collection = ({
  definition,
  wrapWithPaper = true,
  showNewButton = true,
  ...rest
}) => {
  const pid = rest.match.params.id

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `pageTitle`,
        width: '73%',
      },

      {
        ...operations(`pageTitle`, true, true, true, true, false),
        width: '15%',
      },
    ],
    []
  )

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching } = state

  const parentIri = useMemo(() => `${schema.endpoint}/${pid}`, [pid])

  useResourceFetch(
    pid ? parentIri : null,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const defaultFilters = pid
    ? {
        'exists[parent]': true,
        pagination: true,
        'parent.uuid': pid,
      }
    : {
        'exists[parent]': false,
        pagination: true,
      }


  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const collectionId = 'main_menu'

  return (
    <WrapComponent key={pid}>
      { isFetching ? <Loader/> :
      <CollectionHeader
        buttonTitle={translate('Dodaj nową pozycję')}
        buttonPath={
          pid ? routes().newParent.path.replace(':pid', pid) : routes().new.path
        }
        hideButton={!showNewButton}
      >
        {translate(`Lista${pid ? ' podstron' : ' menu'}`)}
        { parent && parent.uuid ? ` "${parent.pageTitle}"` : null }
      </CollectionHeader>
      }
      {pid ? (
        <span></span>
      ) : (
        <Alert severity="info">
          Uwaga:
          <ul>
            <li> w menu może być maksymalnie 7 elementów</li>
            <li> elementy na pozycji 5,6,7 w menu nie wyswietlają podstron</li>
          </ul>
        </Alert>
      )}
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        editPath={routes().edit.path}
        autoWidth={false}
        orderable={true}
        isRowLinkable={true}
        storeCollectionId={`${collectionId}-${pid}`}
        parameters={[
          {
            name: 'exists[parent]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'parent.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
