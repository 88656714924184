import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'

export const BipPreviewType = ({
  label,
  getValue,
  translationLang = null,
  routes,
}) => {
  const uuid = getValue('uuid')
  const lang = translationLang.split('/')[3]?.toLowerCase()

  const link = `http://${window.location.host
    .replace('admin.', '')
    .replace('localhost:3000', 'teatrpolski.local')}${routes[lang]
    .replace('{uuid}', uuid)
    .replace('{_locale}', lang)}`

  return (
    <>
      {uuid && (
        <Alert severity="info" style={{}}>
          <span>{label} </span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </Alert>
      )}
    </>
  )
}

BipPreviewType.propTypes = {
  routes: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
