import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'
import { LANGS_IRI } from '_lib/langs'

export const contact = (
  blockContactEndpoint,
  definitionRefContact = '#BipPageBlockContact'
) => ({
  name: BLOCK_TYPES_IRI.CONTACT,
  label: 'T_GENERAL_BLOCK_TYPE_CONTACT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Nazwa bloku',
          hint: 'widoczna tylko w PA',
        },
      },
    },

    contacts: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_CONTACT_CONTACTS',
      titleAccessor: `translations.${LANGS_IRI.PL}.nativeTitle`, //`translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefContact,
      endpoint: blockContactEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            titleMain: {
              type: 'string',
              description: 'Główny tytuł działu',
              hint: 'duża czcionka',
            },
            title: {
              description: 'Nazwa stanowiska',
              type: 'string',
            },
            titleText: {
              description: 'Dopisek do nazwy stanowiska',
              type: 'string',
            },
            nameSurname: {
              description: 'Imię i nazwisko',
              type: 'string',
            },

            fieldHead: {
              description: 'Nazwa dodatkowego pola',
              hint:
                'dowolna nazwa np. kolejny email, telefon, “redakcja”, adres strony wwww',
              type: 'string',
            },
            fieldText: {
              description: 'Tekst dodatkowego pola',
              type: 'string',
            },

            contactText: {
              description: 'Dopisek do kontaktu',
              type: 'textarea',
            },
          },
        },

        email2: {
          type: 'string',
          description: 'Email do działu',
        },
        phone3: {
          type: 'string',
          description: 'Tel. do działu',
        },

        email: {
          type: 'string',
          description: 'Email do stanowiska',
        },
        phone1: {
          type: 'string',
          description: 'Tel. do stanowiska',
        },
        phone2: {
          type: 'string',
          description: 'Tel. do stanowiska kom.',
          width: 300,
        },
      },
    },

    // showLine: {
    //   type: 'boolean',
    //   description: 'Pokaż linię pod blokiem',
    // },
  },
})
