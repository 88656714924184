const schema = {
  endpoint: '/api/event_category_cycles',
  clone_endpoint: '/api/event_category_cycles/clone',
  access: 'eventCategoryCycle',
  resource: {
    definition: '#EventCategoryCycle',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
