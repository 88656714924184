import React from 'react'
import schema from '_schema/cookieItem'
import routes from './routes'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { Form } from 'pages'
import { EmbeddedCollection } from 'components/embedded'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const profile = useSelector(state => state.profile)
  const iri = `${schema.endpoint}/${match.params.id}`
  return (
    <div style={{ width: 1000 }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width={'100%'}
        modifyProperties={(properties, resource, schema) => {
          return addOpenformProperties(
            profile.openform,
            properties,
            resource,
            schema
          )
        }}
      >
        <div style={{ marginTop: '30px', marginBottom: '50px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.items.endpoint}?iri[parent]=${iri}&pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.items.properties}
            definitionSchema={definitions[schema.subresources.items.definition]}
            headerTitle={translate('Poszczególne cookies')}
            panelTitle={translate('Cookie')}
            titleAccessor={`translations.${LANGS_IRI.PL}.title`}
            statable={true}
          />
        </div>
      </Form>
    </div>
  )
}
