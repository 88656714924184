const schema = {
  endpoint: '/api/foot_addresses',
  access: 'footAddress',
  resource: {
    definition: '#FootAddress',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      email: {
        type: 'string',
        description: 'Email do miejsca',
      },
      phone1: {
        type: 'string',
        description: 'Tel. do miejsca',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
