export const translations = {
  pl: {
    /* Inside application code */
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_PUBLISH_NEW_VERSION: 'Opublikuj nową wersję',
    T_GENERAL_PUBLISHING_NEW_VERSION: 'Publikuję...',
    T_GENERAL_PUBLISHED_NEW_VERSION: 'Opublikowano nową wersję',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_DESCRIPTION_TILE: 'Krótki opis na kaflu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_LIST: 'Lista',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_RECORD_VIEW: 'Wyświetl',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO:
      'Blok tekstowy ze zdjęciem lub filmem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK: 'Blok tekstowy z linkiem',
    T_GENERAL_BLOCK_TYPE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_TYPE_FILES: 'Blok z plikami',
    T_GENERAL_BLOCK_TYPE_FAQ: 'Blok z FAQ',
    T_GENERAL_BLOCK_TYPE_LOGOTYPES: 'Blok z logotypami',
    T_GENERAL_BLOCK_TYPE_AUDIO: 'Blok z audio',
    T_GENERAL_BLOCK_TYPE_BRANCHES: 'Blok z filiami',
    T_GENERAL_BLOCK_TYPE_ETR: 'Blok z ETR',
    T_GENERAL_BLOCK_TYPE_CONTACT: 'Blok z kontaktami',
    T_GENERAL_BLOCK_TYPE_CITATION: 'Blok z cytatem',
    T_GENERAL_BLOCK_TYPE_AUTHOR: 'Blok z autorem',
    T_GENERAL_BLOCK_TYPE_MULTI_SECTION: 'Blok z wieloma sekcjami',
    T_GENERAL_BLOCK_TYPE_PRACTICAL_INFORMATION:
      'Blok z informacjami praktycznymi',
    T_GENERAL_BLOCK_TYPE_ISSUU: 'Blok z ISSUU',
    T_GENERAL_BLOCK_TYPE_MULTI_SECTION_SECTIONS: 'Sekcje',
    T_GENERAL_BLOCK_TYPE_MULTI_BRANCHES_BRANCHES: 'Filie',
    T_GENERAL_BLOCK_TYPE_CONTACT_CONTACTS: 'Kontakty',
    T_GENERAL_BLOCK_LINK: 'Link',
    T_GENERAL_BLOCK_LINK_TITLE: 'Tytuł przycisku',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_PHOTO2: 'Zdjęcie 2',
    T_GENERAL_PHOTO_DETAIL: 'Zdjęcie detalu (nadpisuje zdjęcie główne)',
    T_GENERAL_VIDEO: 'Film',
    T_GENERAL_AUDIO: 'Plik audio',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_THUMB_TYPE_CROPPED: 'Kadr',
    T_THUMB_TYPE_SCALED: 'Zeskalowany',
    'T_THUMB_API/PAGE_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PAGE_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LIST': 'Kadr',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LISTH': 'Kadr pionowy',
    'T_THUMB_API/HOME_BANNER_ITEM_THUMBS_MAIN': 'Kadr główny',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_BLOCK_ADD_BRANCH: 'Dodaj filię',
    T_GENERAL_BLOCK_ADD_MULTI_SECTION: 'Dodaj sekcję',
    T_GENERAL_BLOCK_SECTION_ATTACH_FILE: 'Dodaj zdjęcie',
    T_GENERAL_BLOCK_ISSUU_EMBED_CODE: 'Kod embed',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_ALT2: 'Znacznik alt zdjęcia 2',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_PHOTO_TYPE: 'Typ zdjęcia',
    T_GENERAL_MEDIA_HIDE_IN_DETAIL: 'Ukryj na detalu artykułu',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_PREVIEW: 'Podgląd',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_AUTHOR: 'Autor',
    T_GENERAL_AUTHOR_NAME: 'Imię autora',
    T_GENERAL_AUTHOR_SURNAME: 'Nazwisko autora',
    T_GENERAL_PHOTO_AUTHOR: 'Autor zdjęcia',
    T_GENERAL_DATE_FROM: 'Data od:',
    T_GENERAL_DATE_TO: 'Data do:',
    T_GENERAL_PUBLICATION_DATE: 'Data publikacji',
    T_GENERAL_CATEGORY: 'Kategoria',
    T_GENERAL_CATEGORIES: 'Kategorie',
    T_GENERAL_LINKED_NEWS: 'Powiązane aktualności',
    T_GENERAL_LINKED_EVENTS: 'Powiązane wydarzenia',
    T_GENERAL_FIRST_NAME: 'Imię',
    T_GENERAL_FULL_NAME: 'Imię i nazwisko',
    T_GENERAL_SURNAME: 'Nazwisko',
    T_GENERAL_PHONE_NUMBER: 'Numer telefonu',
    T_GENERAL_ADD_ARTICLE: 'Dodaj artykuł',
    T_GENERAL_EXTERNAL_LINK: 'Link (otwiera zewnętrzną stronę)',
    T_GENERAL_HIGHLIGHTED: 'Wyróżnienie',
    T_GENERAL_GALLERY: 'Galeria',
    T_GENERAL_ARTICLES: 'Artykuły',
    T_GENERAL_ADDRESS: 'Adres',
    T_GENERAL_PHOTO_MINIMUM_DIMENSIONS: 'Minimalne wymiary',
    T_LINKS_FACEBOOK: 'Link Facebook',
    T_LINKS_YOUTUBE: 'Link Youtube',
    T_LINKS_INSTAGRAM: 'Link Instagram',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_ARTICLES: 'Lista artykułów',
    T_MODULE_ARTICLES_NEW_BUTTON: 'Dodaj nowy artykuł',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',
    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_PACKAGE_FAQS: 'Lista pakietów pytań',
    T_MODULE_PACKAGE_FAQ_ITEMS_EXPAND_TITLE: 'pytania',
    T_MODULE_PACKAGE_FAQ_ITEMS_HEADER_TITLE: 'Pytania',
    T_MODULE_PACKAGE_FAQS_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_LOGOTYPE: 'Lista pakietów logotypów',
    T_MODULE_PACKAGE_LOGOTYPES_NEW_BUTTON: 'Dodaj pakiet logotypów',
    T_MODULE_NEWS: 'Aktualności',
    T_MODULE_NEWS_NEW_BUTTON: 'Dodaj aktualność',
    T_MODULE_NEWS_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_GENERAL_FAQ: 'Pytanie',
    'Faqs package title': 'Tytuł zestawu FAQ',
    'Faq packages': 'FAQ',
    'Logotypes title': 'Tytuł zestawu logotypów',
    'Logotype packages': 'Logotypy',
    'Select package FAQ': 'Wybierz zestaw faq',
    'Select package files': 'Wybierz zestaw plików',
    'Select package gallery': 'Wybierz galerię',
    'Select package logotype': 'Wybierz zestaw logotypów',
    // T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ: 'Blok z FAQ',
    'News list': 'Lista',
    'News categories': 'Kategorie',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_REGISTRIES: 'Rejestry',
    T_MENU_NEWS: 'Aktualności',
    T_MENU_EVENTS: 'Spektakle i Wydarzenia',
    T_MENU_SUGGEST_PURCHASE: 'Zaproponuj zakup',
    T_MENU_PORTAL: 'Portal',
    T_MENU_CUSTOMERS: 'Klienci',
    T_MENU_CUSTOMER_GROUP: 'Grupy',
    T_MENU_MOBILE_APP: 'Aplikacja mobilna',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    /* Thumbs */
    'T_THUMB_API/PAGE_BLOCK_THUMBS_CROPPED_EXAMPLE': 'Kadr przykładowy',
    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'Translator entries': 'Tłumaczenia',
    Articles: 'Artykuły',
    'Page block sections': 'Sekcje bloku strony',
    'Page block section thumbs': 'Kadry sekcji bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    'Code of entry': 'Kod',
    'Administration group': 'Grupa',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins': 'Logowanie zablokowane na 15 minut',
    'Login blocked.': 'Logowanie zablokowane',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
    'Depth exceeded.': 'Głębokość przekroczona',
    'Gallery title': 'Tytuł galerii',
    'Files package title': 'Tytuł zestawu plików',
    Highlighted: 'Wyróżnienie',
    Address: 'Adres',
    'Contact manager': 'Kierownik',
    'Additional offers': 'Dodatkowa oferta',
    Amenities: 'Udogodnienia',
    'Additional information': 'Dodatkowe informacje',
    Directions: 'Jak dojadę',
    'Literary salon': 'Salon literacki',
    'Can open page':
      'Klikalna (użytkownik może otworzyć podstronę, np. w menu)',
    T_EVENT_FORM_ENABLED: 'Włączony formularz zapisów',
    T_EVENT_WITH_AMENITIES: 'Wydarzenie z udogodnieniami',
    T_EVENT_MAX_PARTICIPANTS: 'Maksymalna liczba osób do zapisu',
    T_EVENT_INFORMATION_TITLE: 'Dodatkowe informacje',
    T_MODULE_EVENT_CATEGORY: 'Kategorie',
    T_MODULE_EVENT_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_EVENT_CATEGORY_LITERARY_SALON: 'Salon literacki',
    T_MODULE_EVENT_RECIPIENT: 'Udogodnienia',
    T_MODULE_EVENT_RECIPIENT_NEW_BUTTON: 'Dodaj udogodnienie',
    T_MODULE_EVENT_LOCATION: 'Miejsca',
    T_MODULE_EVENT_LOCATION_NEW_BUTTON: 'Dodaj miejsce',
    T_MODULE_EVENTS: 'Wydarzenia',
    T_MODULE_EVENT_NEW_BUTTON: 'Dodaj wydarzenie',
    T_MODULE_EVENT_LOCATION_SELECT: 'Miejsce',
    T_MODULE_EVENT_CATEGORY_SELECT: 'Kategoria',
    T_MODULE_EVENT_RECIPIENTS_SELECT: 'Udogodnienia',
    T_MODULE_EVENT_TABLE_SUBMISSIONS: 'Zgłoszenia',
    T_MODULE_EVENT_TABLE_PARTICIPANTS: 'Uczestnicy',
    T_MODULE_EVENT_SUBMISSION_EVENT: 'Wydarzenie',
    T_MODULE_EVENT_SUBMISSION: 'Zgłoszenia na wydarzenia',
    T_MODULE_EVENT_SUBMISSION_PARTICIPANTS: 'Uczestnicy',
    T_MODULE_PRACTICAL_INFORMATION: 'Oferty specjalne',
    T_MODULE_OFFER: 'Oferty pracy',
    T_MODULE_OFFER_NEW_BUTTON: 'Dodaj ofertę',
    T_CONFIG_PAGE_TITLE: 'Tytuł strony',
    T_CONFIG_CONTACT_ADDRESS: 'Adres w kontakcie',
    T_CONFIG_EMAIL_SEND_ADDRESS: 'Adres z którego ma miejsce wysyłka email',
    T_MODULE_CONTACT_PEOPLE: 'Kontakt - osoby',
    T_MODULE_CONTACT_PEOPLE_NEW_BUTTON: 'Dodaj osobę',
    T_MODULE_PROJECT_FINISHED: 'Projekt zakończony',
    T_MODULE_PROJECT: 'Projekty',
    T_MODULE_PROJECT_NEW_BUTTON: 'Dodaj projekt',
    T_MODULE_SECTIONS: 'Sekcje',
    T_MODULE_HOME_BANNER_NEW_BUTTON: 'Dodaj baner',
    T_MODULE_HOME_BANNER: 'Baner główny',
    T_MODULE_TENDER: 'Przetargi/zamówienia',
    T_MODULE_TENDER_NEW_BUTTON: 'Dodaj zamówienie lub przetarg',
    T_MODULE_CUSTOMER: 'Czytelnicy',
    T_MODULE_CUSTOMER_NEW_BUTTON: 'Dodaj czytelnika',
    T_MODULE_CUSTOMER_GROUP: 'Grupy czytelników',
    T_MODULE_CUSTOMER_GROUP_NEW_BUTTON: 'Dodaj grupę',
    T_MODULE_GENERAL_ARTICLE: 'Artykuły',
    file_type_table_of_contents: 'Spis treści',
    file_type_part: 'Zakres stron / rozdziały',
    'Nominee full name': 'Imię i nazwisko nominowanego',
    'Additional notes': 'Uwagi',
    Department: 'Dział',
    Year: 'Rok',
    Month: 'Miesiąc',
    'Form enabled': 'Włączony formularz',
    'Project finished': 'Zakończony',
    'Person position': 'Stanowisko',
    'Book author': 'Autor książki',
    'Book title': 'Tytuł',
    'Book year, publisher': 'Wydawca, rok wydania',
    'Linked news category': 'Powiązana kategoria aktualności',
    'Link logotype': 'Link',
    Recipients: 'Odbiorcy',
    Archived: 'Archiwalne',
    January: 'Styczeń',
    February: 'Luty',
    March: 'Marzec',
    April: 'Kwiecień',
    May: 'Maj',
    June: 'Czerwiec',
    July: 'Lipiec',
    August: 'Sierpień',
    September: 'Wrzesień',
    October: 'Październik',
    November: 'Listopad',
    December: 'Grudzień',
    T_GENERAL_EMAIL: 'Email',
    T_GENERAL_CODE_YT: 'Kod filmu youtube',
    Licence: 'Licencja',

    'Show homepage': 'Pokaż na stronie głównej',
    'Short description homepage': 'Opis na stronie głównej',
    'Show footer center': 'Stopka [środek]',
    'Show footer right': 'Stopka [prawa strona]',
    'Practical information': 'Informacje praktyczne',
    T_SECTION_SUGGEST_PURCHASE_MEDIA: '[zdjęcie] - zaproponuj zakup',
    T_SECTION_SUGGEST_PURCHASE_MEDIA_ALT: 'Alt sekcji - zaproponuj zakup',
    'Marker color': 'Kolor znacznika [HEX]',
    'Opening hours news': 'Zmiana godzin otwarcia [AKTUALNOŚĆ]',
    'No results found': 'Brak wyników',
    News: 'Aktualności',
    T_PAGE_HIDE_DETAIL_IMAGE: 'Ukryj obrazek na detalu',
    T_TENDER_TYPE_TENDER: 'Przetarg',
    T_TENDER_TYPE_PROCUREMENT: 'Zamówienie publiczne',
    //THUMBS
    'T_THUMB_API/HOME_BANNER_THUMBS_HOME': 'Strona główna',
    'T_THUMB_API/OFFER_THUMBS_DETAIL': 'Detal',
    'T_THUMB_API/OFFER_THUMBS_HOME': 'Strona główna',
    'T_THUMB_API/EVENT_THUMBS_DETAIL': 'Detal',
    T_THUMB_API_MEDIA_DETAIL: 'Detal',
    T_THUMB_API_MEDIADETAIL_DETAIL: 'Detal',
    T_THUMB_API_MEDIA_OFFER_LIST: 'Lista',
    T_THUMB_API_MEDIA_HOME: 'Strona główna',
    T_THUMB_API_MEDIA_HOME_SECTION: 'Kadr',
    T_THUMB_API_MEDIA2_HOME_SECTION: 'Kadr',
    T_THUMB_API_MEDIA2_HOME_SECTION_SMALL: 'Kadr',
    T_THUMB_API_MEDIA_ARCHIVE: 'Archiwum',
    T_THUMB_API_MEDIADETAIL_DESKTOP: 'Kadr',
    T_THUMB_API_MEDIADETAIL_FULL: 'Kadr',
    T_THUMB_API_MEDIADETAIL_MOBILE: 'Kadr',
    T_THUMB_API_MEDIADETAIL_TABLET: 'Kadr',
    Event: 'Wydarzenie',
    Page: 'Strona',
    'Event categories': 'Kategorie wydarzeń',
    Accessibilities: 'Dostępność',
    T_GENERAL_AUTOCOMPLETE_PLACEHOLDER: 'Zacznij pisać aby zobaczyć opcje',
    'Additional head code': 'Dodatkowy kod sekcji HTML HEAD',
    'Additional body code': 'Dodatkowy kod sekcji HTML BODY',
    'Location title': 'Lokalizacja',
    'Alternative location title': 'Alternatywna lokalizacja',
    'Creation date': 'Utworzono',
    'New password': 'Nowe hasło',

    T_BLOCK_ELEMENT_LABEL_PAGE: 'Wybierz stronę',
    'Block position': 'Pozycja bloku',
    T_GENERAL_BLOCK_POSITION_RIGHT: 'zdjęcie z prawej',
    T_GENERAL_BLOCK_POSITION_LEFT: 'zdjęcie z lewej',
    T_GENERAL_BLOCK_POSITION_FULL: 'pełna szerokość',
    T_GENERAL_BLOCK_TEXT_POSITION_LEFT: 'wyrównany do lewej',
    T_GENERAL_BLOCK_TEXT_POSITION_CENTER: 'środek',

    T_THUMB_API_MEDIA_LIST: 'Lista',
    'Event recipients': 'Udogodnienia',
    'News category': 'Aktualności kategorie',
    'Crew items': 'Aktorzy',
    'Crew item sections': 'Aktorzy - sekcje',

    T_TRANSLATOR_MASS_SELECT_EXPORT: 'Eksport',
    T_TRANSLATOR_MASS_SELECT_EXPORT_DONE: 'Wyeksportowano pomyślnie',
    T_TRANSLATOR_MASS_SELECT_DELETE: 'Usuń',
    T_TRANSLATOR_MASS_SELECT_DELETE_DONE: 'Usunięto wybrane poprawnie',
    'Crew filmmakers': 'Twórcy',
    'Event roles': 'Obsada wydarzenia',
    'Event makers': 'Twórca wydarzenia',
    'Event terms': 'Terminy',
    'Event statics': 'Statyści wydarzenia',
    'Crew item images': 'Zdjęcie aktora',
    'Gallery item': 'Zdjęcie pakietu galeri',
    'Gallery item thumbs': 'Kadr zdjęcia pakietu galeri',

    T_THUMB_API_MEDIA_DETAIL_BIG: 'Detal duży',
    T_THUMB_API_MEDIA3_CADR: 'Kadr',
    T_THUMB_API_MEDIA3_CADR_BIG: 'Kadr duży',
    T_THUMB_API_MEDIA_GALLERY: 'Zdjęcie galerii',
    T_THUMB_API_MEDIA_THUMB_BIG: 'Kadr duży',
    T_THUMB_API_MEDIA_THUMB_SMALL: 'Kadr mały',
    T_THUMB_API_MEDIA_THUMB_VERTICAL: 'Kadr pionowy',
    T_THUMB_API_MEDIA_DESKTOP: 'Kadr na desktop',
    T_THUMB_API_MEDIA_FULL: 'Pełna duży desktop',
    T_THUMB_API_MEDIA_MOBILE: 'Kadr na mobile',
    T_THUMB_API_MEDIA_TABLET: 'Kadr na tablet',
    T_THUMB_API_MEDIA_TABLET_VERTICAL: 'Kadr pionowy na tablet',
    T_THUMB_API_MEDIADETAIL_DETAIL_BIG: 'Detal duży',
    T_THUMB_API_MEDIADETAIL_LIST: 'Kadr na listę',
    T_THUMB_API_MEDIADETAIL_LIST_BIG: 'Kadr duży na listę',
    T_THUMB_API_MEDIADETAIL_LIST_SHEDULE: 'Kadr na harmonogram',
    T_THUMB_API_MEDIA_LIST_BIG: 'Kadr duży na listę',
    T_THUMB_API_MEDIA_LIST_SHEDULE: 'Kadr na harmonogram',
    T_THUMB_API_MEDIA2_ETR: 'Kadr ETR',
    T_THUMB_API_MEDIA_HOME_SECTION_SMALL: 'Kadr mały',

    T_THUMB_API_MEDIA_VIDEO_MASK: 'Kadr maska',
    T_VALIDATION_URL: 'Link musi zaczynać się od http:// lub https://',

    T_RESOURCE_BIP: 'Bip',
    T_MENU_BIP: 'BIP',
    T_CONFIG_BIP_ENTITY_NAME: '[BIP] Podmiot udostępniający informację',
    T_MODULE_BIP_PAGE: 'Struktura stron',
    T_MODULE_BIP_PAGE_ARTICLE: 'Artykuły',
    T_MODULE_BIP_PAGE_MENU_CATEGORY: 'Kategoria menu',
    T_MODULE_BIP_MENU_PAGE_CATEGORY: 'Kategorie menu',
    T_MODULE_BIP_MENU_PAGE_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_BIP_PAGE_ARTICLE_NEW_BUTTON: 'Dodaj artykuł',
    T_MODULE_BIP_PAGE_ALERT_NO_HOMEPAGE: 'Brak strony głównej!',
    T_MODULE_BIP_PAGE_VERSION_PUBLISHED_BADGE: 'Opublikowany',
    T_MODULE_BIP_PAGE_COLLECTION_EDIT_ARTICLE_LINK: 'Edytuj artykuł',
    T_MODULE_BIP_PAGE_COLLECTION_EDIT_PAGE_LINK: 'Edytuj stronę',
    T_MODULE_BIP_PAGE_ALERT_UNPUBLISHED_CHANGES:
      'Artykuł posiada nieopublikowane zmiany',
    T_MODULE_BIP_PAGE_VERSION: 'Wersje',
    T_MODULE_BIP_PAGE_ARTICLE_VERSION: 'Wersje artykułu',
    T_MODULE_BIP_PAGE_ARTICLE_COLLECTION_EDIT_LINK: 'Edytuj artykuł',
    T_MODULE_BIP_ENTITY: 'Podmioty (mapa)',
    T_MODULE_BIP_ENTITY_NEW_BUTTON: 'Dodaj podmiot',

    'Bip pages': 'Struktura stron BIP',

    T_TICKET_BUY: 'Kup bilet',
    T_TICKET_LAST: 'Ostatnie bilety!',
    T_TICKET_OFFICE: 'Kup bilet w kasie',
    T_TICKET_FREE: 'Wstęp wolny',
    T_TICKET_CANCELED: 'Odwołane',
    T_TICKET_SOLDOUT: 'Wyprzedane',
    T_TICKET_FEATURE: 'Wkrótce',

    'Event pricelists': 'Cennik wydarzenia',
  },
}
